* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    font-family: 'Arial', sans-serif; 
    background-color: #002447; 
    color: #fff;
    padding: 40px;
}

.sec1 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    padding-left: 5vh;
    transition-property: all;
}

.background-gradient {
    background: linear-gradient(to bottom, #003366, #002744 100%);
    padding: 40px;
}

.intro1 {
    font-size: 1.2em;
    font-family: 'Courier New', Courier, monospace;
    color: #52e0c8;
    margin-bottom: 5vh;
}

.intro2 {
    font-size: 8vh;
    margin-bottom: 1.5vh;
}

.desc1 {
    font-size: 7.5vh;
    color: #8892b0;
    margin-bottom: 2vh;
}

.desc2 {
    font-size: 18px;
    color: #8892b0;
    line-height: 1.3em;
    margin-right: 50vw;
}

 
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.section-animation {
    opacity: 0;
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.section-visible {
    opacity: 1;
    transform: translateY(0);
    animation: fadeInUp 1s forwards;
}

.sec1 .intro1, .sec1 .intro2, .sec1 .desc1, .sec1 .desc2 {
    opacity: 0;
    transform: translateY(20px);
}

.sec1 .intro1.section-visible, .sec1 .intro2.section-visible, 
.sec1 .desc1.section-visible, .sec1 .desc2.section-visible {
    animation: fadeInUp 1s ease-out forwards;
}

.sec2 .about-text, .sec2 .about-image {
    opacity: 0;
    transform: translateY(20px);
}

.sec2 .about-text.section-visible {
    animation: fadeInLeft 1s ease-out forwards;
}

.sec2 .about-image.section-visible {
    animation: fadeInRight 1s ease-out forwards;
}

.sec3 .section-container {
    opacity: 0;
    transform: translateY(20px);
}

.sec3 .section-container.section-visible {
    animation: fadeInUp 1s ease-out forwards;
}

.featured-projects.section-animation {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.featured-projects.section-visible {
    opacity: 1;
    transform: translateY(0);
    animation: fadeInUp 1s forwards;
}