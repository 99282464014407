/* src/components/SideNav.css */

.sidenav {
    height: 100%;
    width: 7vw;
    position: fixed;
    z-index: 1;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.sidenav a {
    padding: 1vh 1vh;
    width: 40px;
    height: 40px;
    display: flex;
    margin-bottom: 2vh;
    justify-content: center;
}

.contact-link img {
    width: 100%; 
    height: auto; 
}
