/* src/components/TopBar.css */

.top-bar {
    position: fixed;
    background-color: #002447;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.scroll-button {
    background-color: #002447;
    color: #52e0c8;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 1.2em;
    font-family: 'Courier New', Courier, monospace;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.scroll-button:hover {
    background-color: #52e0c8;
    color: #003366;
}
