/* src/components/FeaturedProjects.css */

.featured-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    color: #ccd6f6;
    /* padding-top: 10%; */
}

.section-title {
    margin-top: 15vh;
    font-size: 2.5em;
    color: #52e0c8;
    margin-bottom: 1vh;
    font-family: 'Courier New', Courier, monospace;
}

.project-display {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding-top: 20vh
}

.nav-button {
    background-color: transparent;
    border: none;
    color: #52e0c8;
    font-size: 2em;
    cursor: pointer;
    z-index: 10;
    transition: transform 0.3s;
}

.nav-button:hover {
    transform: scale(1.5);
}

.project-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    width: 70vw;
    height: 80vh;
    transition: transform 0.3s;
}

.project-info h3 {
    font-family: 'Courier New', Courier, monospace;
    color: #52e0c8;
    margin-bottom: 10px;
    font-size: 1.5em;
}

.project-info p {
    color: #8892b0;
    font-size: 1em;
    line-height: 1.5;
}

.project-image {
    height: auto; 
    max-height: 50vh;
    max-width: 60vw;
    object-fit: cover; 
    border-radius: 8px;
    margin: 10px 0;
    transition: transform 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
  
.project-image:hover {
    transform: scale(1.05);
}
  
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #0a192f;
    padding: 30px;
    border-radius: 10px;
    width: 80%;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    color: #ccd6f6;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #52e0c8;
    cursor: pointer;
    transition: transform 0.2s;
}

.close-button:hover {
    transform: scale(1.2);
}

.modal-content h3 {
    color: #52e0c8;
    font-family: 'Courier New', Courier, monospace;
    margin-bottom: 15px;
    font-size: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content p {
    color: #ffffff;
    font-family: 'Arial', sans-serif; 
    font-size: 1.2em;
    line-height: 1.6;
    text-justify: auto;
}

.media-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.media-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    text-align: center;
}

.media-text {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #ffffff;
    font-family: 'Courier New', Courier, monospace;
}

.media-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.media-video {
    max-width: 80%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Transition code */
.project-info.slide-in-next {
    animation: slideInNext 0.5s forwards;
}

.project-info.slide-in-prev {
    animation: slideInPrev 0.5s forwards;
}

.media-link {
    display: inline-block;
    margin-top: 1vh;
    font-size: 1rem;
    color: #52e0c8; 
    text-decoration: none;
    font-family: 'Courier New', Courier, monospace;
    transition: color 0.3s ease, transform 0.3s ease;
}

.media-link:hover {
    color: #88f3d3; 
    transform: scale(1.05); 
    text-decoration: underline; 
}


@keyframes slideInNext {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInPrev {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
